
import {computed, defineComponent, onMounted, watch} from "vue";
import {useRouter} from "vue-router"
import NumberFormat from "@/core/functions/NumberFormat";
import store from "@/store";
import {l} from '@/core/helpers/lang'
export default defineComponent ( {
		name       : "PersonalCard",
		props      : [ "user","name" ],
		components : {},
		emits      : [ 'togglemodalgoster' ],
		setup ( props, context )
		{
      const router = useRouter()
      let top = 0;
      let height = 0;

      if (window.innerWidth <= 586) {
        top = 15;
        height = (window.innerHeight / 100) * 75;
      } else {
        top = 176;
      }


      const go = (id) => {
        router.push({name: props.name, params: {id: id}});
				setTimeout(()=>{
						window.location.reload()
				},500)
      }
      const goSponsor = (uSponsor) => {
          router.push({name: props.name, params: {id: uSponsor}});
          setTimeout(() => {
            window.location.reload()
          }, 500)
      }

      const toggleModalGosterChild = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        context.emit('togglemodalgoster');
      };

      onMounted(() => {
        top = window.innerHeight / 4;
      })

      return {
          toggleModalGosterChild,
          NumberFormat,
          top,
          go,
		      height,
          l,
          goSponsor,
          loginUser : computed(()=>store.getters.currentUser)
      };
		},
} );
