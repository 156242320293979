import * as d3           from "d3";
import objectToUrlParams from "@/core/functions/objectToUrlParams";
import get               from "@/core/functions/get";
import toast             from "@/core/functions/toast";
import { api_url }       from "@/ayar";
import "@/assets/sass/agac.sass";
import Swal              from "sweetalert2";
import FormData          from "form-data";
import { l }             from '@/core/helpers/lang';
import { serialize }     from 'dom-form-serializer';
import "@fortawesome/fontawesome-free/css/all.min.css";
import $ from "jquery";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import yaklasik from "@/core/functions/yaklasik";
import { useRouter } from "vue-router";

export default class Tree
{
	OpenRequest         = {};
	OnClickList         = {};
	OnClickPersonalCard = {};
	router;
	attrs               = {
		id                    : `ID${ Math.floor ( Math.random () * 1000000 ) }`, // Id for event handlings
		svgWidth              : 800,
		svgHeight             : 600,
		marginTop             : 0,
		marginBottom          : 0,
		marginRight           : 0,
		marginLeft            : 0,
		container             : 'body',
		defaultTextFill       : '#2C3E50',
		nodeTextFill          : 'white',
		defaultFont           : 'Helvetica',
		backgroundColor       : '#fafafa',
		data                  : null,
		depth                 : 180,
		duration              : 600,
		strokeWidth           : 1,
		connectorLineWidth    : 2,
		dropShadowId          : null,
		initialZoom           : 1,
		iconWidth             : '36px',
		iconHeight            : '36px',
		borderColor           : 'rgba(174,174,174,1)',
		circleBorderColor     : 'rgba(174,174,174,1)',
		linkColor             : 'rgba(174,174,174,1)',
		imageBorderColor      : 'rgba(255,255,255)',
		strokeBackgroundColor : 'rgba(174,174,174,1)',
		imageBorderWidth      : 1,
		iconUp                : null,
		iconDown              : null,
		iconLeftKonum: -18,
		iconHasChildRightKonum: -12,
		iconHasNotChildRightKonum: -24,
		iconSize              : 22,
		draggable             : false,
		unilevelOptions:{},
		layouts               : {
			treemap : null,
		},
		root                  : {
			x0 : 0,
			y0 : 0,
		},
		allNodes              : [],
		allNodesObj           : undefined,
		svg                   : undefined,
		chart                 : undefined,
		defs                  : undefined,
		onNodeClick           : d => d,
		onDragged             : d => d,
		calc                  : {
			id              : null,
			chartTopMargin  : null,
			chartLeftMargin : null,
			chartWidth      : null,
			chartHeight     : null,
		},
		centerG               : null,
		width                 : 150,
		height                : 150,
		nodeImageWidth        : 140,
		nodeImageHeight       : 140,
		shadow                : false,
		cornerShape           : 'ROUNDED', //['ORIGINAL','ROUNDED','CIRCLE']
	};

	constructor ( options = {} )
	{
		this.attrs = { ...this.attrs, ...options };

		this.initializeEnterExitUpdatePattern ();
		this.router = useRouter();
	}

	initializeEnterExitUpdatePattern ()
	{
		d3.selection.prototype.patternify = function ( params )
		{
			const selector   = params.selector;
			const elementTag = params.tag;
			const data       = params.data || [ selector ];

			// Pattern in action
			let selection = this.selectAll ( '.' + selector )
				.data ( data, ( d, i ) =>
				{
					if ( typeof d === 'object' )
					{
						if ( d.id )
						{
							return d.id;
						}
					}
					return i;
				} );
			selection.exit ()
				.remove ();
			selection = selection.enter ()
				.append ( elementTag )
				.merge ( selection );
			selection.attr ( 'class', selector );

			return selection;
		};
		d3.selection.prototype.closest    = function ( selector )
		{
			let closestMatch               = undefined;
			const matchArr : HTMLElement[] = [];
			this.each ( () =>
			{
				while ( typeof this.parentNode.matches === 'function' && !closestMatch )
				{
					if ( this.parentNode.matches ( selector ) )
					{
						closestMatch = this.parentNode;
						matchArr.push ( this.parentNode );
					}
				}
				closestMatch = undefined;
			} );
			return d3.selectAll ( matchArr );
		};
	}

	// This method retrieves passed node's children IDs (including node)
	getNodeChildrenIds ( {
		data,
		children,
		_children,
	}, nodeIdsStore )
	{

		// Store current node ID
		nodeIdsStore.push ( data.nodeId );

		// Loop over children and recursively store descendants id (expanded nodes)
		if ( children )
		{
			children.forEach ( d =>
			{
				this.getNodeChildrenIds ( d, nodeIdsStore );
			} );
		}

		// Loop over _children and recursively store descendants id (collapsed nodes)
		if ( _children )
		{
			_children.forEach ( d =>
			{
				this.getNodeChildrenIds ( d, nodeIdsStore );
			} );
		}

		// Return result
		return nodeIdsStore;
	}

	// This method can be invoked via chart.setZoomFactor API, it zooms to particulat scale
	setZoomFactor ( zoomLevel )
	{
		const calc = this.attrs.calc;

		// Store passed zoom level
		this.attrs.initialZoom = zoomLevel;

		// Rescale container element accordingly
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.attrs.centerG.attr ( 'transform', ` translate(${ calc.centerX }, ${ calc.nodeMaxHeight / 2 }) scale(${ this.attrs.initialZoom })` );
	}

	render ()
	{
		//InnerFunctions which will update visuals
		const attrs         = this.attrs;
		//Drawing containers
		const container     = d3.select ( attrs.container );
		const containerRect = container.node ()
			.getBoundingClientRect ();
		if ( containerRect.width > 0 ) attrs.svgWidth = containerRect.width;

		//Attach drop shadow id to attrs object
		this.setDropShadowId ( attrs );

		//Calculated properties
		const calc = {
			id              : null,
			chartTopMargin  : null,
			chartLeftMargin : null,
			chartWidth      : null,
			chartHeight     : null,
		};
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		calc.id = `ID${ Math.floor ( Math.random () * 1000000 ) }`; // id for event handlings
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		calc.chartLeftMargin = attrs.marginLeft;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		calc.chartTopMargin = attrs.marginTop;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		calc.chartWidth = attrs.svgWidth - attrs.marginRight - calc.chartLeftMargin;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		calc.chartHeight = attrs.svgHeight - attrs.marginBottom - calc.chartTopMargin;
		attrs.calc       = calc;

		// Get maximum node width and height
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		calc.nodeMaxWidth = d3.max ( attrs.data, ( {
			width,
		} ) => width );
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		calc.nodeMaxHeight = d3.max ( attrs.data, ( {
			height,
		} ) => height );

		// Calculate max node depth (it's needed for layout heights calculation)
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.depth = calc.nodeMaxHeight + 100;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		calc.centerX = calc.chartWidth / 2;

		//********************  LAYOUTS  ***********************
		const layouts = {
			treemap : null,
		};
		attrs.layouts = layouts;

		// Generate tree layout function
		layouts.treemap = d3.tree ()
			.size ( [
				calc.chartWidth,
				calc.chartHeight,
			] )
			.nodeSize ( [
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				calc.nodeMaxWidth + 100,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				calc.nodeMaxHeight + attrs.depth,
			] );

		// ******************* BEHAVIORS . **********************
		const behaviors = {
			zoom : null,
		};

		// Get zooming function
		behaviors.zoom = d3.zoom ()
			.on ( 'zoom', d => this.zoomed ( d ) );

		//****************** ROOT node work ************************

		// Convert flat data to hierarchical
		attrs.root = d3.stratify ()
			.id ( ( {
				nodeId,
			} ) => nodeId )
			.parentId ( ( {
				parentNodeId,
			} ) => parentNodeId ) ( attrs.data );

		// Set child nodes enter appearance positions
		attrs.root.x0 = 0;
		attrs.root.y0 = 0;

		/** Get all nodes as array (with extended parent & children properties set)
         This way we can access any node's parent directly using node.parent - pretty cool, huh?
		 */
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.allNodes = attrs.layouts.treemap ( attrs.root )
			.descendants ();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.allNodesObj = new Map ();

		// Assign direct children and total subordinate children's cound
		attrs.allNodes.forEach ( d =>
		{
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			attrs.allNodesObj.set ( d.id, d );
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			Object.assign ( d.data, {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				directSubordinates : d.children ? d.children.length : 0,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				totalSubordinates : d.descendants ().length - 1,
			} );
		} );

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const children = attrs.root.children;

		if ( children )
		{
			// Collapse all children at first
			children.forEach ( d => this.collapse ( d ) );

			// Then expand some nodes, which have `expanded` property set
			children.forEach ( d => this.expandSomeNodes ( d ) );
		}


		// *************************  DRAWING **************************
		//Add svg
		const svg = container.patternify ( {
			tag      : 'svg',
			selector : 'svg-chart-container',
		} )
			.attr ( 'width', attrs.svgWidth )
			.attr ( 'height', attrs.svgHeight )
			.attr ( 'font-family', attrs.defaultFont )
			.call ( behaviors.zoom )
			.attr ( 'cursor', 'move' )
			.style ( 'background-color', attrs.backgroundColor );
		attrs.svg = svg;

		//Add container g element
		const chart = svg.patternify ( {
			tag      : 'g',
			selector : 'chart',
		} )
			.attr ( 'transform', `translate(${ calc.chartLeftMargin },${ calc.chartTopMargin })` );

		// Add one more container g element, for better positioning controls
		attrs.centerG = chart.patternify ( {
			tag      : 'g',
			selector : 'center-group',
		} )
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			.attr ( 'transform', `translate(${ calc.centerX },${ calc.nodeMaxHeight / 2 }) scale(${ attrs.initialZoom })` );

		attrs.chart = chart;

		// ************************** ROUNDED AND SHADOW IMAGE  WORK USING SVG FILTERS **********************

		//Adding defs element for rounded image
		attrs.defs = svg.patternify ( {
			tag      : 'defs',
			selector : 'image-defs',
		} );

		// Adding defs element for image's shadow
		const filterDefs = svg.patternify ( {
			tag      : 'defs',
			selector : 'filter-defs',
		} );

		// Adding shadow element - (play with svg filter here - https://bit.ly/2HwnfyL)
		const filter = filterDefs.patternify ( {
			tag      : 'filter',
			selector : 'shadow-filter-element',
		} )
			.attr ( 'id', attrs.dropShadowId )
			.attr ( 'y', `${ -50 }%` )
			.attr ( 'x', `${ -50 }%` )
			.attr ( 'height', `${ 200 }%` )
			.attr ( 'width', `${ 200 }%` );

		// Add gaussian blur element for shadows - we can control shadow length with this
		filter.patternify ( {
			tag      : 'feGaussianBlur',
			selector : 'feGaussianBlur-element',
		} )
			.attr ( 'in', 'SourceAlpha' )
			.attr ( 'stdDeviation', 3.1 )
			.attr ( 'result', 'blur' );

		// Add fe-offset element for shadows -  we can control shadow positions with it
		filter.patternify ( {
			tag      : 'feOffset',
			selector : 'feOffset-element',
		} )
			.attr ( 'in', 'blur' )
			.attr ( 'result', 'offsetBlur' )
			.attr ( 'dx', 4.28 )
			.attr ( 'dy', 4.48 )
			.attr ( 'x', 8 )
			.attr ( 'y', 8 );

		// Add fe-flood element for shadows - we can control shadow color and opacity with this element
		filter.patternify ( {
			tag      : 'feFlood',
			selector : 'feFlood-element',
		} )
			.attr ( 'in', 'offsetBlur' )
			.attr ( 'flood-color', 'black' )
			.attr ( 'flood-opacity', 0.3 )
			.attr ( 'result', 'offsetColor' );

		// Add feComposite element for shadows
		filter.patternify ( {
			tag      : 'feComposite',
			selector : 'feComposite-element',
		} )
			.attr ( 'in', 'offsetColor' )
			.attr ( 'in2', 'offsetBlur' )
			.attr ( 'operator', 'in' )
			.attr ( 'result', 'offsetBlur' );

		// Add feMerge element for shadows
		const feMerge = filter.patternify ( {
			tag      : 'feMerge',
			selector : 'feMerge-element',
		} );

		// Add feMergeNode element for shadows
		feMerge.patternify ( {
			tag      : 'feMergeNode',
			selector : 'feMergeNode-blur',
		} )
			.attr ( 'in', 'offsetBlur' );

		// Add another feMergeNode element for shadows
		feMerge.patternify ( {
			tag      : 'feMergeNode',
			selector : 'feMergeNode-graphic',
		} )
			.attr ( 'in', 'SourceGraphic' );

		// Display tree contenrs
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.update ( attrs.root );


		//#########################################  UTIL FUNCS ##################################
		// This function restyles foreign object elements ()


		d3.select ( window )
			.on ( `resize.${ attrs.id }`, () =>
			{
				const containerRect = container.node ()
					.getBoundingClientRect ();
				//  if (containerRect.width > 0) attrs.svgWidth = containerRect.width;
				//	main();
			} );


		return this;
	}

	afterRender ( callback )
	{
		if ( this.attrs.allNodes.length > 0 )
		{
			callback ();
		}
		else
		{
			setTimeout ( () =>
			{
				this.afterRender ( callback );
			}, 600 );
		}
	}

	// This function sets drop shadow ID to the passed object
	setDropShadowId ( d )
	{
		// If it's already set, then return
		if ( d.dropShadowId ) return;

		// Generate drop shadow ID
		let id = `${ d.id }-drop-shadow`;

		// If DOM object is available, then use UID method to generated shadow id
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if ( typeof DOM != 'undefined' )
		{
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			id = DOM.uid ( d.id ).id;
		}

		// Extend passed object with drop shadow ID
		Object.assign ( d, {
			dropShadowId : id,
		} );
	}


	// This function can be invoked via chart.addNode API, and it adds node in tree at runtime
	addNode ( obj )
	{
		const attrs = this.attrs;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.data.push ( obj );

		// Update state of nodes and redraw graph
		this.updateNodesState ();
		return this;
	}

	// This function can be invoked via chart.removeNode API, and it removes node from tree at runtime
	removeNode ( nodeId )
	{
		try {
			const attrs = this.attrs;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const node = attrs.allNodesObj.get(nodeId);

			// Remove all node childs
			if (node) {
				// Retrieve all children nodes ids (including current node itself)
				const nodeChildrenIds = this.getNodeChildrenIds(node, []);

				// Filter out retrieved nodes and reassign data
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				attrs.data = attrs.data.filter(d => !nodeChildrenIds.includes(d.nodeId));

				const updateNodesState = this.updateNodesState.bind(this);
				// Update state of nodes and redraw graph
				updateNodesState();
			}
		}catch(e){
			console.log(e)
		}
	}

	confirmDrag(options:{
		parent_id,
		NodeImg,
		NodeRect,
		circle,
		old,
		d,
	}){
		const _this = this
		let extra  = '';
		const rand = Math.floor ( 100000 + Math.random () * 900000 );
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if ( typeof _this.attrs.unilevelOptions.extraAlert !== 'undefined' )
		{
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if ( _this.attrs.unilevelOptions.extraAlert.length > 0 )
			{
				extra += `<br>Aşağıdan ilgili gelir/puan türünü seçin<br><form action="" id="extra">`;
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			for ( const e in _this.attrs.unilevelOptions.extraAlert )
			{
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const item = _this.attrs.unilevelOptions.extraAlert[ e ];
				if ( typeof item !== 'object' ) continue;
				extra += `<div class="form-check">
						  <input class="form-check-input extra${ rand }" type="checkbox" name="${ item.unique_name }" value="true" checked>
						  <label class="form-check-label" for="flexCheckDefault">
								${ item.name }
						  </label>
					</div>`;
			}
			extra += '</form>';
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		Swal.fire ( {
			title              : 'Emin misiniz?',
			html               : `Bu işlemi geri alamazsınız!${ extra }`,
			type               : 'danger',
			showCancelButton   : true,
			confirmButtonColor : '#f4516c',
			cancelButtonColor  : '#d33',
			confirmButtonText  : l ( 'admin:genel:yerlestir' ),
			cancelButtonText   : l ( 'admin:genel:iptal_et' ),

		} ).then ( ( result ) =>
		{
			if ( result.value )
			{
				const extraSerialize = $ ( '#extra' ).serialize ();
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				get ( `${ api_url }user/bayi/unilevelregenerate/${ options.parent_id.replace ( '0-', '' ) }/${ options.d.id.replace ( '0-', '' ) }${ ( typeof _this.attrs.unilevelOptions.extraAlert !== 'undefined' && _this.attrs.unilevelOptions.extraAlert.length > 0 ) ? '?' + extraSerialize : '' }` )
					.then ( resUn =>
					{
						const dataEdit = resUn.data.map ( _this.getObj );
						// TreeChartData = TreeChartData.concat ( dataEdit );
						for ( const i in dataEdit )
						{
							if ( typeof dataEdit[ i ] !== 'object' ) continue;
							try
							{
								_this.addNode ( dataEdit[ i ] );
							}
							catch ( e )
							{
								console.log ( e );
							}
						}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						for ( const i in _this.attrs.data )
						{
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							if ( typeof _this.attrs.data[ i ] !== 'object' ) continue;
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							if ( _this.attrs.data[ i ].hamData.loading === 1 && _this.attrs.data[ i ].parentNodeId === options.parent_id )
							{
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								_this.removeNode ( _this.attrs.data[ i ].nodeId );
							}
						}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						options.d.data.parentNodeId = options.parent_id;
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						options.d.parent = _this.attrs.allNodesObj.get ( options.parent_id );
						_this.updateNodesState ();
					} );
			}
			else
			{
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				options.circle.attr ( 'transform', `translate(${ options.old.x }, ${ options.old.y })` );
				options.d.x = options.old.x;
				options.d.y = options.old.y;
				_this.updateNodesState ();
			}
			options.circle.attr("dragging", 0);
			if ( options.NodeRect )
			{
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				options.NodeRect.style ( 'visibility', 'unset' );
			}
			if ( options.NodeImg )
			{
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				options.NodeImg.attr ( 'width', options.old.imageWidth ).attr ( 'height', options.old.imageHeight );
			}
		} );
	}

	confirmDragMobil(options:{
		parent_id,
		child_id,
		callback,
	}){
		const _this = this
		let extra  = '';
		const rand = Math.floor ( 100000 + Math.random () * 900000 );
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if ( typeof _this.attrs.unilevelOptions.extraAlert !== 'undefined' )
		{
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if ( _this.attrs.unilevelOptions.extraAlert.length > 0 )
			{
				extra += `<br>Aşağıdan ilgili gelir/puan türünü seçin<br><form action="" id="extra">`;
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			for ( const e in _this.attrs.unilevelOptions.extraAlert )
			{
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const item = _this.attrs.unilevelOptions.extraAlert[ e ];
				if ( typeof item !== 'object' ) continue;
				extra += `<div class="form-check">
						  <input class="form-check-input extra${ rand }" type="checkbox" name="${ item.unique_name }" value="true" checked>
						  <label class="form-check-label" for="flexCheckDefault">
								${ item.name }
						  </label>
					</div>`;
			}
			extra += '</form>';
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		Swal.fire ( {
			title              : 'Emin misiniz?',
			html               : `Bu işlemi geri alamazsınız!${ extra }`,
			type               : 'danger',
			showCancelButton   : true,
			confirmButtonColor : '#f4516c',
			cancelButtonColor  : '#d33',
			confirmButtonText  : l ( 'admin:genel:yerlestir' ),
			cancelButtonText   : l ( 'admin:genel:iptal_et' ),

		} ).then ( ( result ) =>
		{
			if ( result.value )
			{
				const extraSerialize = $ ( '#extra' ).serialize ();
				console.log(options)
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				get ( `${ api_url }user/bayi/unilevelregenerate/${ options.parent_id }/${ options.child_id }${ ( typeof _this.attrs.unilevelOptions.extraAlert !== 'undefined' && _this.attrs.unilevelOptions.extraAlert.length > 0 ) ? '?' + extraSerialize : '' }` )
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					.then (({data})=>
					{
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						options.callback(true, data)
					});
			}
			else
			{
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				options.callback(false)
			}
		} );
	}

	// This function basically redraws visible graph, based on nodes state
	update ( {
		x0,
		y0,
		x,
		y,
	} )
	{
		const attrs = this.attrs;

		//  Assigns the x and y position for the nodes
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const treeData = attrs.layouts.treemap ( attrs.root );

		// Get tree nodes and links and attach some properties
		const nodes = treeData.descendants ()
			.map ( d =>
			{
				// If at least one property is already set, then we don't want to reset other properties
				if ( d.width ) return d;

				// Declare properties with deffault values
				let imageWidth              = 100;
				let imageHeight             = 100;
				const imageBorderColor      = attrs.borderColor;
				let imageBorderWidth        = 0;
				let imageRx                 = 0;
				let imageCenterTopDistance  = 0;
				let imageCenterLeftDistance = 0;
				const borderColor           = attrs.borderColor;
				let backgroundColor         = 'steelblue';
				const width                 = d.data.width;
				const height                = d.data.height;
				let dropShadowId            = `none`;

				// Override default values based on data
				if ( d.data.nodeImage && d.data.nodeImage.shadow )
				{
					dropShadowId = `url(#${ attrs.dropShadowId })`;
				}
				if ( d.data.nodeImage && d.data.nodeImage.width )
				{
					imageWidth = d.data.nodeImage.width;
				}
				if ( d.data.nodeImage && d.data.nodeImage.height )
				{
					imageHeight = d.data.nodeImage.height;
				}
				if ( d.data.nodeImage && d.data.nodeImage.borderWidth )
				{
					imageBorderWidth = d.data.nodeImage.borderWidth;
				}
				if ( d.data.nodeImage && d.data.nodeImage.centerTopDistance )
				{
					imageCenterTopDistance = d.data.nodeImage.centerTopDistance;
				}
				if ( d.data.nodeImage && d.data.nodeImage.centerLeftDistance )
				{
					imageCenterLeftDistance = d.data.nodeImage.centerLeftDistance;
				}
				if ( d.data.backgroundColor )
				{
					backgroundColor = this.rgbaObjToColor ( d.data.backgroundColor );
				}
				if ( d.data.nodeImage && d.data.nodeImage.cornerShape.toLowerCase () === 'circle' )
				{
					imageRx = Math.max ( imageWidth, imageHeight );
				}
				if ( d.data.nodeImage && d.data.nodeImage.cornerShape.toLowerCase () === 'rounded' )
				{
					imageRx = Math.min ( imageWidth, imageHeight ) / 6;
				}

				// Extend node object with calculated properties
				return Object.assign ( d, {
					imageWidth,
					imageHeight,
					imageBorderColor,
					imageBorderWidth,
					borderColor,
					backgroundColor,
					imageRx,
					width,
					height,
					imageCenterTopDistance,
					imageCenterLeftDistance,
					dropShadowId,
				} );
			} );

		// Get all links
		const links = treeData.descendants ()
			.slice ( 1 );

		// Set constant depth for each nodes
		nodes.forEach ( d => d.y = d.depth * attrs.depth );

		// ------------------- FILTERS ---------------------

		// Add patterns for each node (it's needed for rounded image implementation)
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const patternsSelection = attrs.defs.selectAll ( '.pattern' )
			.data ( nodes, ( {
				id,
			} ) => id );

		// Define patterns enter selection
		const patternEnterSelection = patternsSelection.enter ().append ( 'pattern' );

		// Patters update selection
		const patterns = patternEnterSelection.merge ( patternsSelection )
			.attr ( 'class', 'pattern' )
			.attr ( 'height', 1 )
			.attr ( 'width', 1 )
			.attr ( 'id', ( {
				id,
			} ) => id );

		// Add images to patterns
		const patternImages = patterns.patternify ( {
			tag      : 'image',
			selector : 'pattern-image',
			data     : d => [ d ],
		} )
			.attr ( 'x', 0 )
			.attr ( 'y', 0 )
			.attr ( 'height', ( {
				imageWidth,
			} ) => imageWidth )
			.attr ( 'width', ( {
				imageHeight,
			} ) => imageHeight )
			.attr ( 'xlink:href', ( {
				data,
			} ) => data.nodeImage && data.nodeImage.url )
			.attr ( 'viewbox', ( {
				imageWidth,
				imageHeight,
			} ) => `0 0 ${ imageWidth * 2 } ${ imageHeight }` )
			.attr ( 'rel', 'prefetch' )
			.attr ( 'preserveAspectRatio', 'xMidYMin slice' );

		// Remove patterns exit selection after animation
		patternsSelection.exit ()
			.transition ()
			.duration ( attrs.duration )
			.remove ();

		// --------------------------  LINKS ----------------------
		// Get links selection
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const linkSelection = attrs.centerG.selectAll ( 'path.link' )
			.data ( links, ( {
				id,
			} ) => id );

		// Enter any new links at the parent's previous position.
		const linkEnter = linkSelection.enter ()
			.insert ( 'path', 'g' )
			.attr ( 'class', 'link' )
			.attr ( 'd', d =>
			{
				const o = {
					x : x0,
					y : y0,
				};
				return this.diagonal ( o, o );
			} );

		// Get links update selection
		const linkUpdate = linkEnter.merge ( linkSelection );

		// Styling links
		linkUpdate.attr ( 'fill', 'none' )
			.attr ( 'stroke-width', ( attrs.connectorLineWidth || 2 ) )
			.attr ( 'stroke', () =>
			{
				return attrs.linkColor;
			} )
			.attr ( 'stroke-dasharray', ( {
				data,
			} ) =>
			{
				if ( data.dashArray )
				{
					return data.dashArray;
				}
				return '';
			} );

		// Transition back to the parent element position
		linkUpdate.transition ()
			.duration ( attrs.duration )
			.attr ( 'd', d => this.diagonal ( d, d.parent ) );

		// Remove any  links which is exiting after animation
		const linkExit = linkSelection.exit ()
			.transition ()
			.duration ( attrs.duration )
			.attr ( 'd', d =>
			{
				const o = {
					x : x,
					y : y,
				};
				return this.diagonal ( o, o );
			} )
			.remove ();

		// --------------------------  NODES ----------------------
		// Get nodes selection
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const nodesSelection = attrs.centerG.selectAll ( 'g.node' )
			.data ( nodes, ( {
				id,
			} ) => id );

		const _this = this;

		const nodeEnterClick = function ( data, d )
		{
			if ( data.defaultPrevented ) return;
			if ( [ ...data.srcElement.classList ].includes ( 'node-button-circle' ) )
			{
				return;
			}
			const parent = data.srcElement.closest ( 'g.node' );
			attrs.onNodeClick ( parent.dataset.id );
		};

		const dragHandler = d3.drag()
			.on ( 'start', function ( event, d )
			{
				if ( !attrs.draggable )
				{
					return false;
				}
				if ( event.sourceEvent.srcElement.closest ( '.parentLoad' ) )
				{
					const parent = event.sourceEvent.srcElement.closest ( 'g.node' );
					attrs.onNodeClick ( parent.dataset.id );
					return true;
				}
				if ( [ ...event.sourceEvent.srcElement.classList ].includes ( 'node-button-circle' ) )
				{
					return true;
				}
				if ( typeof d.data.yeni === 'undefined' )
				{
					return false;
				}
				if ( d.data.yeni === null )
				{
					return false;
				}
				if ( d.data.yeni === 1 )
				{
					return false;
				}
				if ( d.data.loading === 1 )
				{
					return false;
				}
				if(typeof d.data.hamData.unilevel_yer_degistirilebilir !== 'undefined')
				{
					if(d.data.hamData.unilevel_yer_degistirilebilir === false)
					{
						toast(d.data.hamData.name + " altında bayiler olduğu için ağaçta yer değişimi gerçekleştirilemez", 0)
						return false;
					}
				}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const circle = d3.select(this).attr("dragging", 1);

				let newPosition = {
					x: null,
					y: null,
				};
				let old: { x: any; y: any, imageHeight: any, imageWidth: any } = {
					x: null,
					y: null,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					w: d.width,
					h: d.height,
					imageHeight: d.imageHeight,
					imageWidth: d.imageWidth,
				};
				let target = null;
				let NodeRect = null;
				let NodeImg = null;

				function dragStart ( dragStartEvent, d )
				{
					dragStartEvent.sourceEvent.stopPropagation ();
				}

				const dragged = ( dragEvent, d ) =>
				{
					newPosition = {
						x: dragEvent.x,
						y: dragEvent.y,
					};
					old = {
						x: d.x,
						y: d.y,
						imageHeight: old.imageHeight,
						imageWidth: old.imageWidth,
					};
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					target = document.elementFromPoint(dragEvent.sourceEvent.clientX, dragEvent.sourceEvent.clientY).closest('g.node');
					if (target) {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						NodeRect = circle.select('.node-rect');
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						NodeRect.style('visibility', 'hidden');
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						NodeImg = circle.select('.node-image-group .node-image-rect');
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						NodeImg.attr('width', 60).attr('height', 60);
					}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					circle.attr("transform", `translate(${ newPosition.x }, ${ newPosition.y })`);
				};

				function ended ( endEvent, d )
				{
					let newContainer = _this.attrs.allNodes
						.filter((item) => {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							return yaklasik(newPosition.x, item.x, 100) && yaklasik(newPosition.y, item.y, 100)
						} );
					if ( target && newPosition.x !== null && newPosition.y !== null && typeof newContainer[0] !== "undefined")
					{
						newContainer = newContainer[0]
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						const parent_id = newContainer.id
						const rand = Math.floor ( 100000 + Math.random () * 900000 );

						_this.confirmDrag({
							parent_id,
							NodeImg,
							NodeRect,
							circle,
							old,
							d,
						});

						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						attrs.onDragged ( d, target.dataset.id, ( islemTamam = false ) =>
						{
							if ( islemTamam )
							{
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								circle.attr ( 'transform', `translate(${ newPosition.x }, ${ newPosition.y })` );
								d.x = newPosition.x;
								d.y = newPosition.y;
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								d.data.parentNodeId = target.dataset.id;
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								d.parent = attrs.allNodesObj.get ( target.dataset.id );
							}
							_this.updateNodesState ();
						} );
					}
					else
					{
						console.log(old)
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						circle.attr ( 'transform', `translate(${ old.x }, ${ old.y })` );
						d.x = old.x;
						d.y = old.y;
						_this.updateNodesState ();
					}
					circle.attr("dragging", 0);
					if ( NodeRect )
					{
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						NodeRect.style ( 'visibility', 'unset' );
					}
					if ( NodeImg )
					{
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						NodeImg.attr ( 'width', old.imageWidth ).attr ( 'height', old.imageHeight );
					}
				}

				event.on ( 'start', dragStart )
					.on ( 'drag', dragged )
					.on ( 'end', ended );
			})

		const nodeEnterDrag = d3.drag ()
			.on ( 'start', function ( event, d )
			{
				if ( !attrs.draggable )
				{
					return false;
				}
				if ( event.sourceEvent.srcElement.closest ( '.parentLoad' ) )
				{
					const parent = event.sourceEvent.srcElement.closest ( 'g.node' );
					attrs.onNodeClick ( parent.dataset.id );
					return true;
				}
				if ( [ ...event.sourceEvent.srcElement.classList ].includes ( 'node-button-circle' ) )
				{
					return true;
				}
				if ( typeof d.data.yeni === 'undefined' )
				{
					return false;
				}
				if ( d.data.yeni === null )
				{
					return false;
				}
				if ( d.data.yeni === 1 )
				{
					return false;
				}
				if ( d.data.loading === 1 )
				{
					return false;
				}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const circle = d3.select(event).attr("dragging", 1);
				// const circle = d3.select ( event ).classed ( 'dragging', true );

				let newPosition                                                     = {
					x : null,
					y : null,
				};
				let old : { x : any; y : any, imageHeight : any, imageWidth : any } = {
					x : null,
					y : null,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					w           : d.width,
					h           : d.height,
					imageHeight : d.imageHeight,
					imageWidth  : d.imageWidth,
				};
				let target                                                          = null;
				// let NodeRect                                                        = null;
				// let NodeImg                                                         = null;

				function dragStart ( dragStartEvent, d )
				{
					console.log("dragStartEvent")
					dragStartEvent.sourceEvent.stopPropagation ();
				}

				const dragged = ( dragEvent, d ) =>
				{
					console.log("dragEvent")
					newPosition = {
						x : dragEvent.x,
						y : dragEvent.y,
					};
					old         = {
						x           : d.x,
						y           : d.y,
						imageHeight : old.imageHeight,
						imageWidth  : old.imageWidth,
					};
					try
					{
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						target = document.elementFromPoint ( dragEvent.sourceEvent.clientX, dragEvent.sourceEvent.clientY )
							.closest ( 'g.node' );
					}
					catch ( e )
					{
						target = null;
					}
					if ( target )
					{
						// NodeRect = d3.select ( event )
						// 	.select ( '.node-rect' );
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// NodeRect.style ( 'visibility', 'hidden' );
						// NodeImg = d3.select ( event )
						// 	.select ( '.node-image-group .node-image-rect' );
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// NodeImg.attr ( 'width', 60 )
						// 	.attr ( 'height', 60 );
					}
					circle.attr ( 'transform', `translate(${ newPosition.x }, ${ newPosition.y })` );
				};

				function ended ( endEvent, d )
				{
					console.log(endEvent)
					// circle.classed ( 'active', false );
					console.log(target, newPosition)
					if ( target && newPosition.x !== null && newPosition.y !== null )
					{
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						attrs.onDragged ( d, target.dataset.id, ( islemTamam = false ) =>
						{
							if ( islemTamam )
							{
								circle.attr ( 'transform', `translate(${ newPosition.x }, ${ newPosition.y })` );
								d.x = newPosition.x;
								d.y = newPosition.y;
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								d.data.parentNodeId = target.dataset.id;
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								d.parent = attrs.allNodesObj.get ( target.dataset.id );
							}
							_this.updateNodesState ();
						} );
					}
					else
					{
						circle.attr ( 'transform', `translate(${ old.x }, ${ old.y })` );
						d.x = old.x;
						d.y = old.y;
						_this.updateNodesState ();
					}
					// if ( NodeRect )
					// {
					// 	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// 	// @ts-ignore
					// 	NodeRect.style ( 'visibility', 'unset' );
					// }
					// if ( NodeImg )
					// {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// NodeImg.attr ( 'width', old.imageWidth )
						// 	.attr ( 'height', old.imageHeight );
					// }
				}

				event.on ( 'start', dragStart )
					.on ( 'drag', dragged )
					.on ( 'end', ended );
			} );

		// Enter any new nodes at the parent's previous position.
		const nodeEnter = nodesSelection.enter ()
			.append ( 'g' )
			.attr ( 'class', 'node' )
			.attr ( 'transform', d => `translate(${ x0 }, ${ y0 })` )
			.attr ( 'data-id', d => d.id )
			.attr ( 'cursor', 'pointer' )
			.on ( 'click', nodeEnterClick )
			.call ( dragHandler );

		// Add background rectangle for the nodes
		nodeEnter.patternify ( {
			tag      : 'rect',
			selector : 'node-rect',
			data     : d => [ d ],
		} )
			.style ( 'fill', ( {
				_children,
			} ) => _children ? 'lightsteelblue' : '#fff' );


		// Defined node images wrapper group
		const nodeImageGroups = nodeEnter.patternify ( {
			tag      : 'g',
			selector : 'node-image-group',
			data     : d => [ d ],
		} );

		// Add background rectangle for node image
		nodeImageGroups.patternify ( {
			tag      : 'rect',
			selector : 'node-image-rect',
			data     : d => [ d ],
		} );


		// Node update styles
		const nodeUpdate = nodeEnter.merge ( nodesSelection )
			.style ( 'font', '12px sans-serif' );


		// Add foreignObject element inside rectangle
		const fo = nodeUpdate.patternify ( {
			tag      : 'foreignObject',
			selector : 'node-foreign-object',
			data     : d => [ d ],
		} );

		this.restyleForeignObjectElements ();

		// Add Node button circle's group (expand-collapse button)
		const nodeButtonGroups = nodeEnter.patternify ( {
			tag      : 'g',
			selector : 'node-button-g',
			data     : d => [ d ],
		} )
			.on ( 'click', ( e, d ) => this.onButtonClick ( e, d ) );

		// Add expand collapse button circle
		nodeButtonGroups.patternify ( {
			tag      : 'circle',
			selector : 'node-button-circle',
			data     : d => [ d ],
		} );

		// Add button text
		nodeButtonGroups.patternify ( {
			tag      : 'g',//'foreignObject',
			selector : 'node-button-text',
			data     : d => [ d ],
		} )
			.attr ( 'pointer-events', 'none' );

		// Transition to the proper position for the node
		nodeUpdate.transition ()
			.attr ( 'opacity', 0 )
			.duration ( attrs.duration )
			.attr ( 'transform', ( {
				x,
				y,
			} ) => `translate(${ x },${ y })` )
			.attr ( 'opacity', 1 )
			.attr ( 'visibility', "visible" );

		// Move images to desired positions
		nodeUpdate.selectAll ( '.node-image-group' )
			.attr ( 'transform', ( {
				imageWidth,
				width,
				imageHeight,
				height,
			} ) =>
			{
				const x = -imageWidth / 2 - width / 2;
				const y = -imageHeight / 2 - height / 2;
				return `translate(${ x },${ y })`;
			} )
			.attr ( 'style', d =>
			{
				let hidden = false;
				if ( d.data.yeni === 1 )
				{
					hidden = true;
				}
				if ( typeof d.data.hamData !== 'undefined' )
				{
					if ( d.data.hamData.loading === 1 )
					{
						hidden = true;
					}
				}
				return ( hidden ? 'visibility: hidden' : '' );
			} );

		// Style node image rectangles
		nodeUpdate.select ( '.node-image-rect' )
			.attr ( 'fill', ( {
				data,
				id
			} ) => {
				const url =data.nodeImage && data.nodeImage.url

				return url!==false ? `url(#${id})`:"unset"
			} )
			.attr ( 'width', ( {
				data,
				imageWidth,
			} ) => {
				const url =data.nodeImage && data.nodeImage.url

				return url!==false?imageWidth:0
			} )
			.attr ( 'height', ( {
				data,
				imageHeight,
			} ) => {
				const url =data.nodeImage && data.nodeImage.url

				return url!==false?imageHeight:0
			} )
			.attr ( 'stroke', attrs.imageBorderColor )
			.attr ( 'stroke-width', attrs.imageBorderWidth )
			.attr ( 'rx', ( {
				imageRx,
			} ) => imageRx )
			.attr ( 'y', ( {
				imageCenterTopDistance,
			} ) => imageCenterTopDistance )
			.attr ( 'x', ( {
				imageCenterLeftDistance,
			} ) => imageCenterLeftDistance )
			.attr ( 'filter', ( {
				dropShadowId,
			} ) => dropShadowId );

		// Style node rectangles
		nodeUpdate.select ( '.node-rect' )
			.attr ( 'width', ( {
				data,
			} ) => data.width )
			.attr ( 'height', ( {
				data,
			} ) => data.height )
			.attr ( 'x', ( {
				data,
			} ) => -data.width / 2 )
			.attr ( 'y', ( {
				data,
			} ) => -data.height / 2 )
			.attr ( 'rx', ( {
				data,
			} ) => data.borderRadius || 0 )
			.attr ( 'stroke-width', ( {
				data,
			} ) => data.borderWidth || attrs.strokeWidth )
			.attr ( 'cursor', 'pointer' )
			.attr ( 'stroke', attrs.borderColor )
			.style ( 'fill', ( {
				backgroundColor,
			} ) => backgroundColor );

		// Move node button group to the desired position
		nodeUpdate.select ( '.node-button-g' )
			.attr ( 'transform', ( {
				data,
			} ) => `translate(0,${ data.height / 2 })` )
			.attr ( 'data-id', d => d.id )
			.attr ( 'data-child', d => ( d.data.hamData.id !== this.attrs.id ? 1 : 0 ) )
			.attr ( "data-load", d => d.data.loading )
			.attr ( "data-expanded", d => d.data.expanded ? 1 : 0 )
			.attr ( "data-side", d => d.side )
			.attr ( "data-silinebilir", d => d.data.silinebilir )
			.attr ( "data-yeni", d => d.data.yeni )
			.attr ( 'visible', ( {
				children,
				_children,
			} ) =>
			{
				if ( children || _children )
				{
					return 'visible';
				}
				return 'hidden';
			} )
			.attr ( 'opacity', ( {
				children,
				_children,
			} ) =>
			{
				if ( children || _children )
				{
					return 1;
				}
				return 0;
			} )
			.attr ( 'text-anchor', 'middle' )
			.attr ( 'alignment-baseline', 'middle' );

		// Restyle node button circle
		nodeUpdate.select ( '.node-button-circle' )
			.attr ( 'r', attrs.iconSize )
			.attr ( 'stroke-width', ( {
				data,
			} ) => attrs.strokeWidth )
			.attr ( 'fill', attrs.strokeBackgroundColor )
			.attr ( 'stroke', attrs.circleBorderColor )
			.attr ( "data-id", ( data ) => data.id );

		// Restyle button texts
		nodeUpdate.select ( '.node-button-text' )
			.attr ( 'text-anchor', 'middle' )
			.attr ( 'alignment-baseline', 'middle' )
			.attr ( 'fill', attrs.defaultTextFill )
			.attr ( 'font-size', ( {
				children,
			} ) =>
			{
				if ( children ) return 40;
				return 26;
			} )
			.attr ( 'transform', ( {
				children,
			} ) =>
			{
				if ( children )
				{
					return `translate(${attrs.iconLeftKonum},${attrs.iconHasChildRightKonum})`;
				}
				else
				{
					return `translate(${attrs.iconLeftKonum},${attrs.iconHasNotChildRightKonum})`;
				}
			} )
			.html ( ( {
				children,
			} ) =>
			{
				if ( children )
				{
					return attrs.iconUp ?? `
						<svg
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
							width="${ attrs.iconWidth }"
							height="${ attrs.iconHeight }"
							focusable="false"
							data-prefix="fas"
							data-icon="sort-up"
							class="svg-inline--fa fa-sort-up fa-w-10"
							role="img"
							viewBox="0 0 320 512"
						>
							<path
								fill="currentColor"
								d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"
							/>
						</svg>
					`;
				}
				else
				{
					return attrs.iconDown ?? `
	                    <svg
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
							width="${ attrs.iconWidth }"
							height="${ attrs.iconHeight }"
							focusable="false"
							data-prefix="fas"
							data-icon="sort-down"
							class="svg-inline--fa fa-sort-down fa-w-10"
							role="img"
							viewBox="0 0 320 512"
						>
							<path
								fill="currentColor"
								d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"
							/>
						</svg>
					`;
				}
			} )
			.attr ( 'y', this.isEdge () ? 10 : 0 );

		// Remove any exiting nodes after transition
		const nodeExitTransition = nodesSelection.exit ()
			.attr ( 'opacity', 1 )
			.attr ( 'visibility', "visible" )
			.transition ()
			.duration ( attrs.duration )
			.attr ( 'transform', d => `translate(${ x },${ y })` )
			.on ( 'end', function ( event, d )
			{
				d3.select ( event )
					.remove ();
			} )
			.attr ( 'opacity', 0 )
			.attr ( 'visibility', "hidden" );

		// On exit reduce the node rects size to 0
		// nodeExitTransition.selectAll ( '.node-rect' )
		// 	.attr ( 'width', 10 )
		// 	.attr ( 'height', 10 )
		// 	.attr ( 'x', 0 )
		// 	.attr ( 'y', 0 );
		//
		// // On exit reduce the node image rects size to 0
		// nodeExitTransition.selectAll ( '.node-image-rect' )
		// 	.attr ( 'width', 10 )
		// 	.attr ( 'height', 10 )
		// 	.attr ( 'x', ( {
		// 		width,
		// 	} ) => width / 2 )
		// 	.attr ( 'y', ( {
		// 		height,
		// 	} ) => height / 2 );

		// Store the old positions for transition.
		nodes.forEach ( d =>
		{
			d.x0 = d.x;
			d.y0 = d.y;
		} );

		this.callCustomOnclickFuncs();
	}

	callCustomOnclickFuncs() {
		const _this = this
		$("[data-func]").off("click")
		$("[data-func]").on("click", function (item) {
			switch (item.currentTarget.dataset.func) {
				case "addModal":
					if (typeof item.currentTarget.dataset.id !== 'undefined' && typeof item.currentTarget.dataset.name !== 'undefined' && typeof item.currentTarget.dataset.side !== 'undefined') {
						_this.addModal(item.currentTarget.dataset.id, item.currentTarget.dataset.name, item.currentTarget.dataset.side);
					}
					break;
				case "addToTree":
					if (typeof item.currentTarget.dataset.ustid !== 'undefined' && typeof item.currentTarget.dataset.id !== 'undefined' && typeof item.currentTarget.dataset.side !== 'undefined') {
						_this.addToTree(item.currentTarget.dataset.ustid, item.currentTarget.dataset.id, item.currentTarget.dataset.side);
					}
					break;
				case "deleteNode":
					if (typeof item.currentTarget.dataset.id !== 'undefined') {
						_this.deleteNode(item.currentTarget.dataset.id);
					}
					break;
			}
		})
	}

	addModal(id, name, side) {
		const modal = new bootstrap.Modal(document.getElementById('myModal'))
		modal.show();
		switch (side) {
			case 'left':
				$('.sagKol').addClass('hidden');
				$('.solKol').removeClass('hidden');
				$('#kisiTaraf').text(name + '\'ın Soluna');
				break;
			case 'right':
				$('.sagKol').removeClass('hidden');
				$('.solKol').addClass('hidden');
				$('#kisiTaraf').text(name + '\'ın Sağına');
				break;
		}
		get(`${api_url}user/bayi/my_references/${id}`).then(({data}) => {
			if (data.durum === true) {
				let html = ``;
				for (const i in data.mesaj) {
					const item = data.mesaj[i];
					// Standart userId ye atılıyordu. Şimdi herhangi bir yere atılabilir. Bu değiştirilebilir
					if (typeof item.sponsor_id !== 'undefined') {
						html += `<tr>
                        <td>${item.sponsor_id}</td>
	                        <td>${item.name}</td>
	                        <td>
	                        	${side === 'left' ? `<i class = "fa fa-chevron-left anchor" data-func="addToTree" data-ustid="${id}" data-id="${item.sponsor_id}" data-side="left"></i>` : ''}
							</td>
	                        <td>
	                        	${side === 'right' ? `<i class = "fa fa-chevron-right anchor" data-func="addToTree" data-ustid="${id}" data-id="${item.sponsor_id}" data-side="right"></i>` : ''}
							</td>
	                    </tr>`;
					}
				}
				$('#my_references_table').html(html);
				this.callCustomOnclickFuncs();
			}
		})
	}

	addToTree(ustid, id, side) {
		get(`${api_url}bayi/add_to_tree/${ustid}/${id}/${side}`).then(({data}) => {
			if (data.durum === true) {
				toast(l('admin:genel:agaca_eklendi')).then(()=>{
					window.location.reload()
				})
			}
		});
	}

	deleteNode(id) {
		const _this = this
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		Swal.fire({
			title: 'Emin misiniz?',
			text: 'Bu işlemi geri alamazsınız!',
			type: 'success',
			showCancelButton: true,
			confirmButtonColor: '#f4516c',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sil!',
			cancelButtonText: 'Vazgeç',
		}).then((result) => {
			if (result.value) {
				get(api_url + 'bayi/delete_node/' + id).then(res => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					Swal.fire(
						'Silindi!',
						l('admin:genel:agacatan_silindi'),
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						"success",
					);
					setTimeout(() => {
						window.history.go(-1);
						window.location.reload();
					}, 800)
				})
			}
		});
	}

	// This function detects whether current browser is edge
	isEdge ()
	{
		return window.navigator.userAgent.includes ( 'Edge' );
	}

	/* Function converts rgba objects to rgba color string
	 {red:110,green:150,blue:255,alpha:1}  => rgba(110,150,255,1)
	 */
	rgbaObjToColor ( {
		red,
		green,
		blue,
		alpha,
	} )
	{
		return `rgba(${ red },${ green },${ blue },${ alpha })`;
	}

	// Generate custom diagonal - play with it here - https://observablehq.com/@bumbeishvili/curved-edges?collection=@bumbeishvili/work-components
	diagonal ( s, t )
	{

		// Calculate some variables based on source and target (s,t) coordinates
		const x        = s.x;
		const y        = s.y;
		const ex       = t.x;
		const ey       = t.y;
		const xrvs     = ex - x < 0 ? -1 : 1;
		const yrvs     = ey - y < 0 ? -1 : 1;
		const rdef     = 35;
		const rInitial = Math.abs ( ex - x ) / 2 < rdef ? Math.abs ( ex - x ) / 2 : rdef;
		const r        = Math.abs ( ey - y ) / 2 < rInitial ? Math.abs ( ey - y ) / 2 : rInitial;
		const h        = Math.abs ( ey - y ) / 2 - r;
		const w        = Math.abs ( ex - x ) - r * 2;

		// Build the path
		return `
             M ${ x } ${ y }
             L ${ x } ${ y + h * yrvs }
             C  ${ x } ${ y + h * yrvs + r * yrvs } ${ x } ${ y + h * yrvs + r * yrvs } ${ x + r * xrvs } ${ y + h * yrvs + r * yrvs }
             L ${ x + w * xrvs + r * xrvs } ${ y + h * yrvs + r * yrvs }
             C ${ ex }  ${ y + h * yrvs + r * yrvs } ${ ex }  ${ y + h * yrvs + r * yrvs } ${ ex } ${ ey - h * yrvs }
             L ${ ex } ${ ey }
           `;
	}

	restyleForeignObjectElements ()
	{
		const attrs = this.attrs;

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.svg.selectAll ( '.node-foreign-object' )
			.attr ( 'width', ( {
				width,
			} ) => width )
			.attr ( 'height', ( {
				height,
			} ) => height )
			.attr ( 'x', ( {
				width,
			} ) => -width / 2 )
			.attr ( 'y', ( data ) => (data.data.yeni || data.data.id.toString().includes('-yeni-')) ? -data.height / 1.5 : 0 )
			.style ( 'color', 'white' )
			.attr ( 'data-id', d => d.data.hamData.id )
			.html ( ( {
				data,
				width,
				height,
			} ) => `<div class="node-foreign-object-div ${data.yeni === 0 ?"personal_card_open" : "" }" data-yeni="${data.yeni}" data-id="${ data.id }" style="width: ${ width }px; height: ${ height }px; color: white;">
				${ data.template }
			</div>` );
	}

	onClickCall ( id )
	{
		eval ( this.OnClickList[ id ] );
	}

	// Toggle children on click.
	onButtonClick ( e, d )
	{
		// If childrens are expanded
		if ( d.children )
		{
			const children = d.children;
			//Collapse them
			d._children    = d.children;
			d.children     = null;

			// Set descendants expanded property to false
			this.setExpansionFlagToChildren ( d, true );
		}
		else
		{
			eval ( this.OnClickList[ d.id ] );
			// Expand children
			d.children  = d._children;
			d._children = null;

			const children = d.children;

			// Set each children as expanded
			d.children.forEach ( ( {
				data,
			} ) => data.expanded = true );

			this.setExpansionFlagToChildren ( d, false );
		}

		// Redraw Graph
		this.update ( d );
	}

	evalFunction ( func )
	{
		eval ( this[ func.func ] ( func.args ) );
	}

	checkTip ( id )
	{
		let result = "";
		if ( id.toString ().indexOf ( '-yeni-' ) > -1 )
		{
			result = "yeni";
		}
		if ( id.toString ().indexOf ( '-loading-' ) > -1 )
		{
			result = "loading";
		}

		return result;
	}

	// This function changes `expanded` property to descendants
	setExpansionFlagToChildren ( {
		data,
		children,
		_children,
	}, flag )
	{
		// Set flag to the current property
		data.expanded = flag;
		// Loop over and recursively update expanded children's descendants
		if ( children )
		{
			children.forEach ( d =>
			{
				this.setExpansionFlagToChildren ( d, flag );
			} );
		}

		// Loop over and recursively update collapsed children's descendants
		if ( _children )
		{
			_children.forEach ( d =>
			{
				this.setExpansionFlagToChildren ( d, flag );
			} );
		}
	}

	// This function can be invoked via chart.setExpanded API, it expands or collapses particular node
	setExpanded ( id, expandedFlag )
	{
		const attrs = this.attrs;
		// Retrieve node by node Id
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const node = attrs.allNodesObj.get ( id );

		// If node exists, set expansion flag
		if ( node ) node.data.expanded = expandedFlag;

		// First expand all nodes
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.root.children.forEach ( d => this.expand ( d ) );

		// Then collapse all nodes
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.root.children.forEach ( d => this.collapse ( d ) );

		// Then expand only the nodes, which were previously expanded, or have an expand flag set
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.root.children.forEach ( d => this.expandSomeNodes ( d ) );

		// Redraw graph
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.update ( attrs.root );
	}

	// Method which only expands nodes, which have property set "expanded=true"
	expandSomeNodes ( d )
	{
		// If node has expanded property set
		if ( d.data.expanded )
		{
			// Retrieve node's parent
			let parent = d.parent;

			// While we can go up
			while ( parent )
			{
				// Expand all current parent's children
				if ( parent._children )
				{
					parent.children = parent._children;
				}

				// Replace current parent holding object
				parent = parent.parent;
			}
		}

		// Recursivelly do the same for collapsed nodes
		if ( d._children )
		{
			d._children.forEach ( ch => this.expandSomeNodes ( ch ) );
		}

		// Recursivelly do the same for expanded nodes
		if ( d.children )
		{
			d.children.forEach ( ch => this.expandSomeNodes ( ch ) );
		}
	}


	// This function updates nodes state and redraws graph, usually after data change
	updateNodesState ()
	{
		const attrs = this.attrs;
		// Store new root by converting flat data to hierarchy
		attrs.root  = d3.stratify ()
			.id ( ( {
				nodeId,
			} ) => nodeId )
			.parentId ( ( {
				parentNodeId,
			} ) => parentNodeId ) ( attrs.data );

		// Store positions, where children appear during their enter animation
		attrs.root.x0 = 0;
		attrs.root.y0 = 0;

		// Store all nodes in flat format (although, now we can browse parent, see depth e.t.c. )
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.allNodes = attrs.layouts.treemap ( attrs.root )
			.descendants ();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.allNodesObj = new Map ();

		// Store direct and total descendants count
		attrs.allNodes.forEach ( d =>
		{
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			attrs.allNodesObj.set ( d.id, d );
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			Object.assign ( d.data, {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				directSubordinates : d.children ? d.children.length : 0,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				totalSubordinates : d.descendants ().length - 1,
			} );
		} );

		// Expand all nodes first
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.root.children && attrs.root.children.forEach ( this.expand );

		// Then collapse them all
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.root.children && attrs.root.children.forEach ( d => this.collapse ( d ) );

		// Then only expand nodes, which have expanded proprty set to true
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.root.children && attrs.root.children.forEach ( ch => this.expandSomeNodes ( ch ) );

		// Redraw Graphs
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.update ( attrs.root );
	}

	// Function which collapses passed node and it's descendants
	collapse ( d )
	{
		if ( d.children )
		{
			d._children = d.children;
			d._children.forEach ( ch => this.collapse ( ch ) );
			d.children = null;
		}
	}

	// Function which expands passed node and it's descendants
	expand ( d )
	{
		if ( d._children )
		{
			d.children = d._children;
			d.children.forEach ( ch => this.expand ( ch ) );
			d._children = null;
		}
	}

	// Zoom handler function
	zoomed ( d )
	{
		const attrs = this.attrs;
		const chart = attrs.chart;

		// Store it
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		attrs.lastTransform = d.transform;

		// Reposition and rescale chart accordingly
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		chart.attr ( 'transform', d.transform );

		// Apply new styles to the foreign object element
		if ( this.isEdge () )
		{
			this.restyleForeignObjectElements ();
		}
	}

	getObj = ( d ) =>
	{
		let width      = this.attrs.width;
		let height     = this.attrs.height;
		const expanded = d.expanded === 1; //d.id=="O-6"
		let template   = '';
		if ( d.loading === 0 )
		{
			if ( d.yeniekle !== 0 )
			{
				width    = 150; //Math.round(Math.random()*50+300);
				height   = 150; //Math.round(Math.random()*20+130);
				template = `
					<div style="width: 150px;height: 150px;border-radius: 14%;background-color: #dedede;" class=" justify-content-center parentLoad" data-load="0" data-func="addModal" data-id="${d.onClick.id}" data-name="${d.onClick.name}" data-side="${d.onClick.side}" class="addAnchor addModal">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="-5 -5 30 30" fill="gray">
                              <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"></path>
                            </svg>
					</div>
				`;
			}
			else
			{
				template = d.template;
			}
		}
		else
		{
			width    = 150; //Math.round(Math.random()*50+300);
			height   = 150; //Math.round(Math.random()*20+130);
			template = `<div style="width: 100%;height: 100%;border-radius: 50%;background-color: #dedede;"><img src="${ d.img }" style="width: 100%;height: 100%;border-radius: 50%;"></div>`;
		}

		return {
			nodeId             : '0-' + d.id,
			id                 : d.id,
			parentNodeId       : d.parent_id,
			yeni               : d.yeniekle,
			loading            : d.loading,
			silinebilir        : d.silinebilir,
			hamData            : d,
			width              : width,
			height             : height,
			borderWidth        : 0.6,
			borderRadius       : 5,
			borderColor        : {
				red   : 255,
				green : 0,
				blue  : 0,
				alpha : ( d.loading === 1 ? 0 : 1 ),
			},
			backgroundColor    : {
				alpha : 1,
				blue  : 255,
				green : 255,
				red   : 255,
			},
			nodeIcon           : {
				icon : 'https://to.ly/1yZnX',
				size : 30,
			},
			nodeImage          : ( d.loading === 0 && d.yeniekle === 0 ? {
				url                : d.img,
				width              : this.attrs.nodeImageWidth,
				height             : this.attrs.nodeImageHeight,
				centerTopDistance  : 50,
				centerLeftDistance : 200,
				cornerShape        : this.attrs.cornerShape,
				shadow             : this.attrs.shadow,
				borderWidth        : 0,
				borderColor        : {
					red   : 19,
					green : 123,
					blue  : 128,
					alpha : 0,
				},
			} : null ),
			template           : template,
			connectorLineColor : {
				red   : 174,
				green : 174,
				blue  : 174,
				alpha : 1,
			},
			connectorLineWidth : 2,
			directSubordinates : 4,//??
			dashArray          : '',
			expanded           : expanded,
			totalSubordinates  : 2,
		};
	};

	userKart ( d, route )
	{
		const id = d.split ( '0-' )[ 1 ];
		// const requestCartUrl = `${ api_url }${ route }/bayi/yerlesimKartDetay/${ id }`;
		// get ( requestCartUrl )
		// 	.then ( res =>
		// 	{
		// 		let left_html   = '';
		// 		let right_html  = '';
		// 		const left_url  = `${ api_url }${ route }/bayi/bayi_yerlesim_agacim_req/${ res.data.left_id }`;
		// 		const right_url = `${ api_url }${ route }/bayi/bayi_yerlesim_agacim_req/${ res.data.right_id }`;
		// 		if ( res.data.left_id !== null )
		// 		{
		// 			left_html = `
		// 		<label>Sol Kol</label>
		// 		<span><a href="${ left_url }" data-toggle="tooltip" data-placement="right" title="${ res.data.left_name }">${ res.data.left_id }</a></span>
		// 		<hr></hr>
		// 	`;
		// 		}
		// 		if ( res.data.right_id !== null )
		// 		{
		// 			right_html = `
		// 		<label>Sağ Kol</label>
		// 		<span><a href="${ right_url }" data-toggle="tooltip" data-placement="right" title="${ res.data.right_name }">${ res.data.right_id }</a></span>
		// 	`;
		// 		}
		// 		let userLink;
		// 		if ( route === 'admin' )
		// 		{
		// 			userLink = api_url + 'admin/bayi/bayi-yerlesim-agacim/' + res.data.id;
		// 		}
		// 		else
		// 		{
		// 			userLink = api_url + 'user/bayi/bayi-yerlesim-agacim/' + res.data.id;
		// 		}
		// 	} );
	}

	getTreeData = ( id, route, yeni = 0, options = {}, getUserCard = null ) =>
	{
		const requestUrl = `${ api_url }${ route }/bayi/bayi-yerlesim-agacim/${ id }/${ yeni }${ options !== {} ? '?' + objectToUrlParams ( options ) : '' }`;
		if ( typeof this.OpenRequest[ id ] !== 'undefined' )
		{
			return;
		}
		this.OpenRequest[ id ] = id;
		get ( requestUrl )
			.then ( data =>
			{
				const dataEdit = data.data.map ( this.getObj );
				for ( const i in dataEdit )
				{
					const item = dataEdit[ i ];
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					this.OnClickList[ item.nodeId ] = `this.getTreeData ( '${ item.id }', '${ route }', 1, ${ JSON.stringify ( options ) } ${ getUserCard ? ', ' + getUserCard.toString () : '' } )`;
					if ( getUserCard )
					{
						this.OnClickPersonalCard[ item.nodeId ] = {
							func : "getPersonalData",
							args : {
								sponsor_id : item.id,
								callback   : getUserCard,
							},
						};
					}
				}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if ( this.attrs.allNodes.length === 0 )
				{
					this.attrs.data        = dataEdit;
					this.attrs.initialZoom = 0.3;
					this.attrs.draggable   = false;
					this.attrs.onNodeClick ( d =>
					{
						if ( d.indexOf ( '-yeni-' ) > -1 )
						{
							let id = d.split ( '-yeni-' )[ 0 ];
							id     = id.split ( '0-0-' )[ 1 ];
						}
						else
						{
							this.userKart ( d, route );
						}
					} );
					this.render ();
				}
				else
				{
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					for ( const i in dataEdit )
					{
						if ( typeof dataEdit[ i ] !== 'object' ) continue;
						try
						{
							this.addNode ( dataEdit[ i ] );
						}
						catch ( e )
						{
							console.log ( e );
						}
					}
				}
				if ( yeni === 1 )
				{
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					for ( const i in this.attrs.data )
					{
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						if ( typeof this.attrs.data[ i ] !== 'object' ) continue;
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						if ( this.attrs.data[ i ].hamData.loading === 1 && this.attrs.data[ i ].parentNodeId === ( '0-' + id ) )
						{
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							this.removeNode ( this.attrs.data[ i ].nodeId );
						}
					}
				}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if (this.attrs.data[0].silinebilir === 1) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					this.agactanSilButonuOlustur(this.attrs.data[0].id);
				}
			} );

		return this;
	};

	agactanSilButonuOlustur(id) {
		const agactanSilHtml = `<a class="nav-link active" style="background-color: #F1416C!important" href="javascript:" data-func="deleteNode" data-id="${id}"><span class="nav-text">Ağaçtan Sil</span></a>`;
		$('#agactanSilContainer').html(agactanSilHtml);
		this.callCustomOnclickFuncs();
	}

	getUnilevelTreeData = ( id, route, yeni = 0, draggable = false, options = {}, getUserCard = null ) =>
	{
		if ( typeof this.OpenRequest[ id ] !== 'undefined' )
		{
			return;
		}
		this.OpenRequest[ id ] = id;
		get ( `${ api_url }${ route }/bayi/unileveltree/${ id }/${ yeni }${ options !== {} ? '?' + objectToUrlParams ( options ) : '' }` )
			.then ( data =>
			{
				const dataEdit = data.data.map ( this.getObj );
				for ( const i in dataEdit )
				{
					const item = dataEdit[ i ];
					this.attrs.unilevelOptions = options
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					this.OnClickList[ item.nodeId ] = `this.getUnilevelTreeData ( '${ item.id }', '${ route }', 1, ${ draggable }, ${ JSON.stringify ( options ) }${ getUserCard ? ', ' + getUserCard.toString () : '' } )`;
					if ( getUserCard )
					{
						this.OnClickPersonalCard[ item.nodeId ] = {
							func : "getPersonalData",
							args : {
								sponsor_id : item.id,
								callback   : getUserCard,
							},
						};
					}
				}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if ( this.attrs.allNodes.length === 0 )
				{
					this.attrs.data        = dataEdit;
					this.attrs.initialZoom = 0.3;
					this.attrs.draggable   = draggable;
					this.attrs.onNodeClick ( d =>
					{
						if ( d.includes ( '-yeni-' ) ) return;
						// $ ( '#kartModal' ).remove ();
						const id = d.split ( '0-' )[ 1 ];
						get ( `${ api_url }${ route }/bayi/yerlesimKartDetay/${ id }` ).then ( res =>
						{
							let left_html  = '';
							let right_html = '';
							if ( res.data.left_id !== null )
							{
								left_html = `
									<label>Sol Kol</label>
									<span><a href="${ api_url + route + '/bayi/unileveltree/' + res.data.left_id }${ options !== {} ? '?' + objectToUrlParams ( options ) : '' }" data-toggle="tooltip" data-placement="right" title="${ res.data.left_name }">${ res.data.left_id }</a></span>
									<hr></hr>
								`;
							}
							if ( res.data.right_id !== null )
							{
								right_html = `
									<label>Sağ Kol</label>
									<span><a href="${ api_url + route + '/bayi/unileveltree/' + res.data.right_id }${ options !== {} ? '?' + objectToUrlParams ( options ) : '' }" data-toggle="tooltip" data-placement="right" title="${ res.data.right_name }">${ res.data.right_id }</a></span>
								`;
							}
							let unilevelSelectBox = '';
							if ( typeof res.data.uygunAltSponsorlar !== 'undefined' && res.data.uygunAltSponsorlar !== null )
							{
								unilevelSelectBox = '<select id="uygunAltSponsorlar" name="type_check">';
								unilevelSelectBox += '<option value="">Seciniz</option>';
								for ( let i = 0; i < res.data.uygunAltSponsorlar.length; i++ )
								{
									const item = res.data.uygunAltSponsorlar[ i ];
									unilevelSelectBox += `<option value="${ item.id }">${ item.name }</option>`;
								}
								unilevelSelectBox += '</select>';
							}
							let unilevelTotalPoint = '';
							if ( typeof res.data.totalpoint !== 'undefined' && res.data.totalpoint !== null )
							{
								const userIds = Object.keys ( res.data.totalpoint );
								if ( userIds.length !== 0 )
								{
									unilevelTotalPoint = '<div class=\'float-left w-100\'>';
									unilevelTotalPoint += '<h5 class="w-100">Hat Puanları</h5>';
									userIds.forEach ( userId =>
									{
										for ( let i = 0; i < res.data.totalpoint[ userId ].length; i++ )
										{
											const item = res.data.totalpoint[ userId ][ i ];
											unilevelTotalPoint += `<span class='font-weight-bold float-left w-100'>${ item.point_key } : <i>${ item.toplam_amount }</i></span>`;
										}
									} );
									unilevelTotalPoint += '</div>';
								}
							}
						} );
					} );
					this.render ();
				}
				else
				{
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					for ( const i in dataEdit )
					{
						if ( typeof dataEdit[ i ] !== 'object' ) continue;
						try
						{
							this.addNode ( dataEdit[ i ] );
						}
						catch ( e )
						{
							console.log ( e );
						}
					}
				}
				setTimeout ( () =>
				{
					if ( yeni === 1 )
					{
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						for ( const i in this.attrs.data )
						{
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							if ( typeof this.attrs.data[ i ] !== 'object' ) continue;
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							if ( this.attrs.data[ i ].hamData.loading === 1 && this.attrs.data[ i ].parentNodeId === ( '0-' + id ) )
							{
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								this.removeNode ( this.attrs.data[ i ].nodeId );
							}
						}
					}
				}, 500 );
			} );

		return this;
	};

	getPersonalData ( {
		sponsor_id,
		callback,
	} )
	{
		get ( `${ api_url }bayi/yerlesimKartDetay/${ sponsor_id }` ).then ( callback );
	}

	id ( id )
	{
		this.attrs.id = id;
		return this;
	}

	svgWidth ( svgWidth )
	{
		this.attrs.svgWidth = svgWidth;
		return this;
	}

	svgHeight ( svgHeight )
	{
		this.attrs.svgHeight = svgHeight;
		return this;
	}

	marginTop ( marginTop )
	{
		this.attrs.marginTop = marginTop;
		return this;
	}

	marginBottom ( marginBottom )
	{
		this.attrs.marginBottom = marginBottom;
		return this;
	}

	marginRight ( marginRight )
	{
		this.attrs.marginRight = marginRight;
		return this;
	}

	marginLeft ( marginLeft )
	{
		this.attrs.marginLeft = marginLeft;
		return this;
	}

	container ( container )
	{
		this.attrs.container = container;
		return this;
	}

	defaultTextFill ( defaultTextFill )
	{
		this.attrs.defaultTextFill = defaultTextFill;
		return this;
	}

	nodeTextFill ( nodeTextFill )
	{
		this.attrs.nodeTextFill = nodeTextFill;
		return this;
	}

	defaultFont ( defaultFont )
	{
		this.attrs.defaultFont = defaultFont;
		return this;
	}

	backgroundColor ( backgroundColor )
	{
		this.attrs.backgroundColor = backgroundColor;
		return this;
	}

	data ( data )
	{
		this.attrs.data = data;
		return this;
	}

	depth ( depth )
	{
		this.attrs.depth = depth;
		return this;
	}

	duration ( duration )
	{
		this.attrs.duration = duration;
		return this;
	}

	strokeWidth ( strokeWidth )
	{
		this.attrs.strokeWidth = strokeWidth;
		return this;
	}

	connectorLineWidth ( connectorLineWidth )
	{
		this.attrs.connectorLineWidth = connectorLineWidth;
		return this;
	}

	dropShadowId ( dropShadowId )
	{
		this.attrs.dropShadowId = dropShadowId;
		return this;
	}

	initialZoom ( initialZoom )
	{
		this.attrs.initialZoom = initialZoom;
		return this;
	}

	iconWidth ( iconWidth )
	{
		this.attrs.iconWidth = iconWidth;
		return this;
	}

	iconHeight ( iconHeight )
	{
		this.attrs.iconHeight = iconHeight;
		return this;
	}

	borderColor ( borderColor )
	{
		this.attrs.borderColor = borderColor;
		return this;
	}

	circleBorderColor ( circleBorderColor )
	{
		this.attrs.circleBorderColor = circleBorderColor;
		return this;
	}

	linkColor ( linkColor )
	{
		this.attrs.linkColor = linkColor;
		return this;
	}

	imageBorderColor ( imageBorderColor )
	{
		this.attrs.imageBorderColor = imageBorderColor;
		return this;
	}

	strokeBackgroundColor ( strokeBackgroundColor )
	{
		this.attrs.strokeBackgroundColor = strokeBackgroundColor;
		return this;
	}

	imageBorderWidth ( imageBorderWidth )
	{
		this.attrs.imageBorderWidth = imageBorderWidth;
		return this;
	}

	iconUp ( iconUp )
	{
		this.attrs.iconUp = iconUp;
		return this;
	}

	iconDown ( iconDown )
	{
		this.attrs.iconDown = iconDown;
		return this;
	}

	iconSize ( iconSize )
	{
		this.attrs.iconSize = iconSize;
		return this;
	}

	draggable ( draggable )
	{
		this.attrs.draggable = draggable;
		return this;
	}

	onNodeClick ( onNodeClick )
	{
		this.attrs.onNodeClick = onNodeClick;
		return this;
	}

	onDragged ( onDragged )
	{
		this.attrs.onDragged = onDragged;
		return this;
	}
}