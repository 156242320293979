export default function objectToUrlParams ( params )
{
	let str = "";
	for ( const key in params )
	{
		if ( str != "" )
		{
			str += "&";
		}
		str += key + "=" + encodeURIComponent ( params[ key ] );
	}
	
	return str;
}